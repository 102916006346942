import React from "react"
import Layout from "../components/layout/Layout.js"
import {
  Title,
  SubHeading,
  Heading,
  Subtitle,
} from "../components/title/Title.js"
import { Column50, Column33 } from "../components/column/Column.js"
import { Row } from "../components/row/Row.js"
import { ImageHome,
  ImageNarrow,
  ImageContainer,
} from "../components/image/Image.js"
import Trusted  from "../blocks/Trusted.js"
import { TextMedium } from "../components/text/Text.js"
import { Link } from "../components/link/Link.js"
import { PrimaryButton, ButtonContainer } from "../components/button/Button.js"
import featuredImage from "../static/contentaudit.png"
import img3 from '../static/blogImages/CTR and conversion rate per content type.png'
import imgAuth from "../static/authors/andreeamacoveiciuc.jpeg"
import Seo from "../components/seo"
import { HeroSection, BelowFold, Section } from "../components/section/Section"
import img1 from "../static/contentaudit-when.png"
import CtaBlock from "../blocks/CtaBlock"
import { Quote } from "../components/quote/Quote"
import { Testimonials } from "../components/testimonial/Testimonial.js"


function ContentAudit({ children, isCentered, ...rest }) {
  return (
    <Layout>
      <Seo
      title="Content Audit"
      description="Know what content you have, how it performs and 
              where your gaps are with a full content audit"
      image={featuredImage}
      featuredImage={featuredImage} />

      <HeroSection 
        title="Get the full picture of your content" 
        text="Know what content you have, how it performs, and 
        where your gaps are with a full-scope content audit." 
        buttonText="Get a quote" 
        buttonLink="/contact/"
        imageUrl={featuredImage}
        imageAlt="Content audit for SaaS"
      />
    
      <Trusted />
      
      <Section isCentered>
        <Row isCentered>
        <Subtitle isPrimary subtitle="When should you do a content audit?" />
        </Row>
        <Row isCentered>
          <Column50>
            <ImageHome src={img1} alt="homepage" />
          </Column50>
          <Column50>
            <>
            <SubHeading isBold subheading="You have no idea what's in your CMS"
            />
            <TextMedium
              textMedium="You've gathered a lot of content throughout the years and nobody has the full picture anymore. 
              You have no idea which pages are outdated and which need to be archived."
              />
            </>
            <>
            <SubHeading isBold subheading="Your content isn't performing"
            />
            <TextMedium
              textMedium="You're attracting a lot of organic traffic, but your content isn't generating any conversions. 
              You'd like to optimize your pages for better ROI, but don't know where to start."
              />
            </>
            <>
            <SubHeading isBold subheading="Your website structure is outdated"
            />
            <TextMedium
              textMedium="Your business evolved and your offerings have changed. Your website's information architecture 
              is outdated and doesn't serve your business goals anymore."
              />
            </>
            <>
            <SubHeading isBold subheading="Your user journeys are confusing" />
            <TextMedium
              textMedium="Your content isn't mapped to the user journey and you don't have enough conversion content. You don't know what
              types of content to create for the full funnel."
              />
            </>
          </Column50>
        </Row>
      </Section>
   
      <BelowFold isLight>
        <Row isCentered>
        <Subtitle isPrimary isCentered subtitle="How will a content audit help you?" />
        </Row>
        <Row isCentered>
          <Column33 isFloating>
            <Heading isCentered heading="Know what pages to optimize" />
            <TextMedium textMedium="A full audit helps identify the content that drives the most traffic but doesn't generate conversions." />
            <TextMedium textMedium="By getting the full picture of your content and its performance, you can prioritize pages for optimization in a structured, focused manner." />
            {/* <Link
              href="/blog/process-optimize-existing-content/"
              anchortext="See an example of optimization plan"
              isCentered
            /> */}
          </Column33>
          <Column33 isFloating>
            <Heading
              isCentered
              heading="Focus your efforts for maximum impact"
            />
            <TextMedium textMedium="A content audit shows you where you're missing opportunities, so it helps you narrow down the scope and focus your efforts and budget where it matters the most." />
            <TextMedium textMedium="It helps you identify gaps and areas where your competitors are performing better." />
            {/* <Link
              href="/case-studies/saas-content-strategy-should-you-build-funnel-educate-users-case-study/"
              anchortext="Read a case study"
              isCentered
            /> */}
          </Column33>
          <Column33 isFloating>
            <Heading isCentered heading="Make data-driven decisions" />
            <TextMedium textMedium="During a content audit, you analyze the queries targeted, the types of content that generate results and the structure of your top performing pages." />
            <TextMedium textMedium="The qualitative analysis helps you identify patterns and approach content creation in a data-driven manner." />
            {/* <Link
              href="/blog/how-do-content-audit-insights/"
              anchortext="See an example of a content audit"
              isCentered
            /> */}
          </Column33>
        </Row>
      </BelowFold>

      {/* Case study example */}

       <Section isCentered>
        <Row wrap isCentered>
           <TextMedium textMedium="Featured case study" />
          <Heading isCentered heading="Which content type works better for SaaS startups?" />
        </Row>
        <Row isCentered>
          <Column50>
            <ImageContainer>
              <ImageNarrow src={img3} alt="organic-growth" />
            </ImageContainer>
          </Column50>
          <Column50 isCentered>
            <Quote
              isLight
              quoteText={`While there’s no standard answer that applies to all startups in all niches, there
               are some content types that tend to perform better for SaaS products.`}
              authorName="Andreea"
              authorTitle="Content Strategist"
              avatarImg={imgAuth}
            />
            <PrimaryButton
              isCentered
              buttonText="Read the case study"
              buttonLink="/case-studies/content-types-saas-startups-case-study/"
            />
          </Column50>
        </Row>
      </Section>


      <Section isCentered>
        <Row isCentered>
         <Subtitle isCentered isPrimary subtitle="How we work" />
        </Row>
        <Row isCentered>
          <Column50 isFloating>
            <Heading isCentered heading="1. Inventory" />
            <TextMedium
              textMedium="We inventorize all your content, including redirected pages, to get the full picture. 
            Based on the performance of your pages, we narrow down the scope for the qualitative analysis."
            />
          </Column50>
          <Column50 isFloating>
            <Heading isCentered heading="2. Qualitative analysis" />
            <TextMedium
              textMedium="We look at the format, structure, length and depth of your content, and rate it for customer-content fit. 
            We check if your content matches your business goals and user intent."
            />
          </Column50>
        </Row>
        <Row isCentered>
          <Column50 isFloating>
            <Heading isCentered heading="3. Mapping" />
            <TextMedium
              textMedium="We map your content to the funnel stages and highlight the gaps. Based on your goals, we define the content 
              that needs to be created, and the pages that need to be optimized."
            />
          </Column50>
          <Column50 isFloating>
            <Heading isCentered heading="4. Content roadmap" />
            <TextMedium
              textMedium="With these findings, we define your content roadmap. We prioritize the content types and topics 
              more likely to drive conversions, so you can immediately start the implementation."
            />
          </Column50>
        </Row>
      </Section>

      <Testimonials/>

      <CtaBlock />
    </Layout>
  )
}

export default ContentAudit

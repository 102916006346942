import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"

export const QuoteWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  padding: ${sizes.sizeL} ${sizes.sizeXXXXL};
  margin-bottom: ${sizes.sizeXL};
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  background-color: ${props =>
    props.isLight ? `${colors.colorContentLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;
`

export const QuoteText = styled.p`
  font-size: ${fontSizes.fontL};
  margin-bottom: ${sizes.sizeL};
  line-height: ${fontSizes.fontXL};
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`

export const QuoteAuthor = styled.div`
  display: flex;
  flex-flow: column wrap;
`

export const AuthorName = styled.p`
  font-size: ${fontSizes.fontM};
  margin-bottom: 0;
  font-weight: 400;
  width: 200px;
`

export const AuthorTitle = styled.p`
  font-size: ${fontSizes.fontS};
`

export const AuthorAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
  margin-right: ${sizes.sizeM};
  margin-left: 0;
`

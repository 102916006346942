import React from "react"
import {
  QuoteWrapper,
  QuoteText,
  QuoteAuthor,
  AuthorAvatar,
  AuthorName,
  AuthorTitle,
  AuthorWrapper
} from "./style.js"

export const Quote = ({ ...props }) => (
  <QuoteWrapper isLight={props.isLight} isCentered={props.isCentered}>
      <QuoteText>{props.quoteText}</QuoteText>
      <AuthorWrapper>
        <AuthorAvatar src={props.avatarImg} />
        <QuoteAuthor>
            <AuthorName>{props.authorName} </AuthorName>
            <AuthorTitle>{props.authorTitle} </AuthorTitle>
        </QuoteAuthor>
      </AuthorWrapper>
  </QuoteWrapper>
)
